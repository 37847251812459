import React from "react";

const Loading = () => {
    return (
        <div>
            Loading...
        </div>
    );
}
 
export default Loading;